import { gql } from "@apollo/client";


export const GET_PENDING_CASH_PAYMENT = gql`
  query getPendingCashPaymentMypage($user_id: Int!) {
    getPendingCashPaymentMypage(user_id: $user_id) {
      id
      status
      payment_method
      payment_product {
        name
      }

      created_at
      document {
        id
        name
        price
        documentUrlList {
          object_url
        }
      }
    }
  }
`;
export const GET_PAYMENT_INFO = gql`
  query getPaymentInfoByMypage($user_id: Int!) {
    getPaymentInfoByMypage(user_id: $user_id) {
      created_at
      payment_product_id
      payment_product_name
      document_id
      document_name
      amount
      payment_method
      status
    }
  }
`;

export const GET_PAYMENT_INFO_V2 = gql`
  query getPaymentInfoByMypageV2($user_id: Int!) {
    getPaymentInfoByMypageV2(user_id: $user_id) {
      id
      point
      status
      created_at
      payment_log {
        payment_product_id
        status
        payment_method
        amount
        payment_product {
          name
        }
      }
      document {
        name
        price
        id
        documentUrlList {
          object_url
          type
        }
      }
    }
  }
`;

export const GET_PAYMENT_USER_FINANCE_PAGE = gql`
  query getPaymentInfoUserFinancePage($user_id: Int!) {
    getPaymentInfoUserFinancePage(user_id: $user_id) {
      id
      point
      status
      created_at
      payment_log {
        payment_product_id
        status
        payment_method
        amount
        payment_product {
          name
        }
      }
      document {
        name
        price
        id
        documentUrlList {
          object_url
          type
        }
      }
    }
  }
`;

export const GET_USER_INFO = gql`
  query getUserInfo {
    getUserInfo {
      id
      name
      current_point
      isAdmin
    }
  }
`;


export const GET_DOWNLOAD_INFO = gql`
  query getDownloadInfoByMypage($user_id: Int!) {
    getDownloadInfoByMypage(user_id: $user_id) {
      id
      point

      document_id
      document_name
      point
      remain_cnt
      created_at
      object_url
    }
  }
`;